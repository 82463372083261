<template>
  <div>

    <div class="p-4 rounded" style="background: #f6f6f6">
      <h2 class="text-primary text-uppercase text-base">Basic infromation</h2>
      <div class="md-layout md-gutter mt-4">
        <div class="md-layout-item md-size-75">
          <p class="text-uppercase text-italic text-base font-semibold text-gray-600 mb-2">Full Name</p>
          <h2 class="text-uppercase font-bold text-lg">{{ formDetails.name }}</h2>
        </div>
      </div>
      <div class="md-layout md-gutter mt-4">
        <div class="md-layout-item md-size-70">
          <p class="text-uppercase text-italic text-base font-semibold text-gray-600 mb-2">Email Address</p>
          <h2 class="text-uppercase font-bold text-lg">{{ formDetails.email }}</h2>
        </div>
        <div class="md-layout-item md-size-30">
          <p class="text-uppercase text-italic text-base font-semibold text-gray-600 mb-2">Contact Number</p>
          <h2 class="text-uppercase font-bold text-lg">{{ formDetails.user_profile.contact_number }}</h2>
        </div>
      </div>
    </div>

    <div class="p-4 rounded mt-4" style="background: #f6f6f6">
      <h2 class="text-primary text-uppercase text-base">Details</h2>
      <div class="md-layout md-gutter mt-4">
        <div class="md-layout-item md-size-75">
          <p class="text-uppercase text-italic text-base font-semibold text-gray-600 mb-2">Country</p>
          <h2 class="text-uppercase font-bold text-lg">{{
              formDetails.user_profile.country == "others" ? formDetails.user_profile.your_country : formDetails.user_profile.country
            }}</h2>
        </div>
      </div>
      <div class="md-layout md-gutter mt-4">
        <div class="md-layout-item md-size-70">
          <p class="text-uppercase text-italic text-base font-semibold text-gray-600 mb-2">City</p>
          <h2 class="text-uppercase font-bold text-lg">{{ formDetails.user_profile.city }}</h2>
        </div>
        <div class="md-layout-item md-size-30">
          <p class="text-uppercase text-italic text-base font-semibold text-gray-600 mb-2">Postal Code</p>
          <h2 class="text-uppercase font-bold text-lg">{{ formDetails.user_profile.postal_code }}</h2>
        </div>
      </div>
    </div>

    <div class="p-4 rounded mt-4" style="background: #f6f6f6">
      <h2 class="text-primary text-uppercase text-base">Details</h2>
      <div class="md-layout md-gutter mt-4">
        <div class="md-layout-item md-size-75">
          <p class="text-uppercase text-italic text-base font-semibold text-gray-600 mb-2">Courese</p>
          <h2 class="text-uppercase font-bold text-lg">{{ formDetails.packages ? formDetails.packages.name : '' }}</h2>
        </div>
      </div>
    </div>

    <div class="flex justify-end mt-4">
      <!-- <md-button class="ml-auto text-uppercase" @click="clearAll">Clear all</md-button> -->
      <Button
          :loading="loading"
          class="bg-victoria rounded text-white mr-0"
          label="Save & Next"
          type="button"
          @click="submit">
      </Button>
    </div>

  </div>
</template>

<script>
import {
  Button
} from "@/components"
import {mapActions, mapMutations} from 'vuex';

export default {
  components: {
    Button
  },

  data() {
    return {
      loading: false,
      formDetails: {},
    }
  },

  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar"
    }),

    ...mapActions({
      actStudentRegister: "registration/actStudentRegister",
    }),

    getFormDetails() {
      this.formDetails = this.getLocal('registration-student-info');
    },

    submitDataRearrange() {
      let submitData = {
        email: this.formDetails.email,
        name: this.formDetails.name,
        type: this.formDetails.type,
        active: this.formDetails.active,
        user_profile: {
          contact_number: this.formDetails.user_profile.contact_number,
          country_id: this.formDetails.user_profile.country_id == "others" ? this.formDetails.user_profile.your_country_id : this.formDetails.user_profile.country_id,
          postal_code_id: this.formDetails.user_profile.postal_code_id,
        },
        organizations: this.formDetails.organization_id,
        packages: [
          this.formDetails.packages ? this.formDetails.packages.id : ''
        ],
      }
      return submitData;
    },

    submit() {
      this.loading = true;
      let submitData = this.submitDataRearrange();
      this.actStudentRegister(submitData).then((response) => {
        console.log(this.formDetails.packages);
        this.setLocal('registration-student-info', {
          user_id: response.user_id,
          name: submitData.name,
          email: submitData.email,
          packages: this.formDetails.packages,
        })
       
        this.$emit("menu", this.$route.name);
        this.$router.push({ name: 'registrationPayment' });
      }).catch((error) => {
        let errors = error.response.data.details;
        if (errors.email) errors = errors.email[0];
        if(errors != null){
          this.setShowSnackbar(errors);
          setTimeout(() => {
            this.dialog(false);
          }, 1000);
        }
      }).finally(() => this.loading = false)
    },

    clearAll() {
      this.$emit("clear-all", "");
    }
  },

  created() {
    this.getFormDetails();
  }
}
</script>